import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Styles/Terms.css"
import { MdOutlineContactSupport } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";






export const PrivacyPolicy = () => {
  return (
    <div>
      <div className="terms-container">
      <Link to='/'>
      <img className='augio-logo-terms-page' src='https://augio-other-images.s3.amazonaws.com/color_mock_01.png'></img>
      </Link>
        

        <div className="inner-text">
        <h4 className="terms-header">Privacy Policy</h4>
          <p>
            <p className="terms-subheader">Summary</p>
            <p className="terms-subtext">
              This Privacy Policy covers Augio’s treatment of information that
              Augio collects when you access the site as a Consumer and when You
              use the Services provided by Augio as a Customer. This Privacy
              Policy does not apply to any and all practices of third parties
              that Augio does not own or control, or individuals not employed by
              Augio. If you have any questions, comments or requests regarding
              this Privacy Notice or want to exercise any of your privacy
              rights, please reach out to us at legal@augio.com.
              <p className="terms-subheader">
                Information and Data Augio Collects
              </p>
              The Information Augio collects from Consumers and Customers is
              used to personalize and improve the Service we provide through the
              Site. Augio collects the following types of information from
              Consumers and Customers:
              <br></br>
              <br></br>
              Account Information
              <ul>
                <li>Email Address</li>
                <li>Name</li>
              </ul>
              Profile Information
              <ul>
                <li>Location - State</li>
                <li>Links to profiles on additional social media platforms</li>
              </ul>
              Communication
              <ul>
                <li>
                  When communicating with us or users through our site or via
                  email we collect information about the communication and any
                  other information you provide in your response.
                </li>
              </ul>
              Automatically Collected Information
              <ul>
                <li>Usage</li>
                <li>Location</li>
                <li>Cookies</li>
              </ul>
              <p className="terms-subheader">Information You Provide to Us</p>
              To use the Services provided by Augio, You will be required to
              create an account. This will include Augio collecting the
              following Information, without limitation: your full name, email
              address, mailing address, billing address, phone number, IP
              address, password, contact information, and zip code. All
              information provided to Augio in relation to your account is
              classified as information provided to Augio.
              <p className="terms-subheader">
                Information Automatically Collected.{" "}
              </p>
              Augio receives and stores certain information whenever a user
              interacts with the Site or uses the Services. This includes your
              IP address, cookie information, page(s) requested, device sensor
              data including latitude and longitude and/or geolocation, details
              of your interaction with any third-party apps, widgets, modals,
              and/or advertisements which are linked to or made available on
              Augio, and information about the website you visited immediately
              prior to your interaction with Augio. Users can enable or disable
              location services when you use Augio at any time, through the
              settings on your device. This is not an all-inclusive list of
              information Augio automatically collects. Augio will also collect
              information showing the number and frequency of visitors to
              specific pages or media on the Site.
              <p className="terms-subheader">
                Information automatically collected from Users third-party use
              </p>
              Augio may collect Personal Data from third-party websites and
              applications, including things that other third parties may post
              publicly about you or otherwise provide to us. When registering
              for an Augio account through a third-party application, we may
              collect certain information about you. For example, if you
              register using your Facebook account, Facebook will, with your
              consent, share your Facebook user ID, first and last name, email
              address and other profile information. Facebook collects this
              information pursuant to its privacy policy. To control the
              information that Augio receives from Facebook, or any other
              similar application, you can update your privacy setting in your
              account.
              <p className="terms-subheader">
                Cookies/Tracking Technology Used by Augio
              </p>
              A cookie is a small text file that's placed on your computer or
              mobile device when you visit one of our websites. Augio, along
              with some of our affiliates and third-party service providers, may
              use a few different types of cookies.
              <p className="terms-subheader">
                Why does Augio use and collect the above information and
                cookies/tracking technologies?
              </p>
              Cookies/tracking technologies allow Augio to operate the Site,
              provide the Services and enhance your user experience. These
              tracking technologies also allow Augio to perform analytics and
              provide advertising that is relevant to you. Additional purposes
              your information is collected and cookies and tracking
              technologies are used may be as follows:
              <ul>
                <li>
                  To operate and maintain safe, secure, and reliable Site and
                  Service
                </li>
                <li>
                  To identify Users and the actions Users perform on the
                  Platform
                </li>
                <li>To provide technical functionality to the Platform;</li>
                <li>
                  To obtain data for research and development of new products,
                  services, or functionality to improve User's experience on
                  Augio
                </li>
                <li>
                  To communicate with Users for marketing, support, and/or
                  promotional purposes through email, text message, push, or
                  in-app notifications, if not disabled by Users
                </li>
                <li>
                  To process payments for subscriptions and paid-for features;
                </li>
                <li>
                  To detect fraud or unauthorized manipulation of Augio's data
                </li>
                <li>
                  To provide you with advertising, features, information, links,
                  messaging, or other content based on your geolocation and time
                  you are using Augio's services
                </li>
                <li>
                  To display relevant ads on behalf of advertisers and/or
                  sponsors
                </li>
                <li>
                  To deliver to you a customized experience on the Platform
                  based on prior usage
                </li>
                <li>
                  To provide the users who provide content to the Augio platform
                  with statistics and information on who is listening to their
                  content
                </li>
                <li>
                  To enable you to participate in interactive features on our
                  Augio's platform and application
                </li>
                <li>To provide customer support</li>
                <li>
                  To perform accounting, audits and other internal functions
                </li>
                <li>
                  To ensure compliance with our Terms of Service (TOS), this
                  Privacy Policy, or with any applicable state, local, federal,
                  or international laws an
                </li>
                <li>
                  To comply with a request or order from courts, law enforcement
                  or other authorities
                </li>
              </ul>
              <p className="terms-subheader">
                Turning off cookies/tracking technologies
              </p>
              Most internet browsers provide an option to turn off cookies or
              other tracking technologies, which prevent your browser from
              accepting any cookies. Augio does not currently respond to support
              Do Not Track requests.
              <p className="terms-subheader">
                Actions Taken in Regard to Users' Information
              </p>
              <p className="terms-subheader">Requesting your information</p>
              You can unsubscribe from our communications at any time by
              selecting the “unsubscribe” button in the footer of our emails. If
              you would like us to delete your information entirely, or request
              to see the information we store about you, please send an email to
              support@Augio.com. Unless a specific request to unsubscribe and/or
              delete your data, your continued use of the Services gives us
              permission to manage and o use your data as described in this
              Privacy Policy.
              <p className="terms-subheader">When information may be shared</p>
              Augio may share your information with third-party sites, based in
              the U.S., to enhance Users' experience on the platform and the
              functionality of the Platform. Augio may also use and share
              specific information with third parties if abuses of Augio's
              services are found to have occurred. This may include, but is not
              limited to, instances of copyright infringement, libel and
              slander, and fraudulent or illegal activity. If Augio is involved
              in any transaction regarding the ownership and operation of the
              platform, your information may be transferred. Augio will provide
              Users with notice prior to your information being transferred and
              possibly subject to an alternate privacy policy.
              <p className="terms-subheader">Reserved rights</p>
              Augio, at all times, reserves the right to respond to and comply
              with law enforcement and judicial process, including, but not
              limited to subpoenas.
              <p className="terms-subheader">
                {" "}
                Third-Party Service Providers and Partners
              </p>
              Our content may link to third-party websites and services that are
              outside our control. We are not responsible for the security or
              privacy of any information collected by other websites or other
              services. You should exercise caution and review the privacy
              statements applicable to the third-party websites and services you
              use. Our partners use this information to recognize you across
              different channels and platforms, including but not limited to,
              computers and mobile devices, over time for advertising,
              analytics, attribution, and reporting purposes. Augio may also
              share Users' information with third party platform providers who
              assist us in advertising our Services to others who may be
              interested in the services we provide. We also partner with third
              parties (such as Facebook, Instagram, Twitter, Google, or other
              similarly situated platforms) who use cookies and similar tracking
              technologies to serve targeted advertising/content to you via the
              relevant third-party platform based on your profile/interests
              (also known as “interest-based advertising”). Augio will not share
              information that identifies you by name with unaffiliated third
              parties for their own use, however these third parties may be able
              to identify you with sufficient data from other sources. You can
              control what advertisements you receive via the privacy settings
              on the relevant provider's platform and, if you have any questions
              or concerns, you should contact the third party's support
              department.
              <p className="terms-subheader">Children</p>
              Other than the online contact information required to obtain
              parental consent, we do not knowingly collect any Personal
              Information from Users under the age of 18 unless the User's
              parent or legal guardian has first provided us with consent for
              that User to use the Services and disclose Personal Information to
              us. If you are using Augio and are under the age of 18, please do
              not send any Personal Information to us if your parent or
              guardians has not given prior consent. If we learn we have
              collected Personal Information from a User under the age of 18
              without parental consent, or if we learn a User under the age of
              18 has provided us Personal Information beyond what we request
              from the User, we will delete that information as soon as
              possible. If you believe that a User under the age of 18 may have
              provided us Personal Information in violation of this Privacy
              Policy, please contact us at legal@augio.com.
              <p className="terms-subheader">Rights of California</p>
              California law provides additional rights to minors under the age
              of eighteen (18) residing in California. You may request the
              removal of any of your Information you submitted while under the
              age of 18. Augio can and will remove any Information upon request
              from our systems but cannot guarantee removal from system's not
              under the control of Augio. YOU AND YOUR PARENT OR GUARDIAN
              UNDERSTAND IT IS NEAR IMPOSSIBLE TO HAVE INFORMATION ENTIRELY
              REMOVED FROM THE INTERNET ONCE IT APPEARS.
              <p className="terms-subheader">
                Process of requesting information deletion
              </p>
              To make a an information deletion request, contact us at
              support@augio.com from the email associated with your Augio
              account with subject line “Request for Information Deletion.” Our
              support team will verify and respond to your request consistent
              with applicable law. For verification purposes, our support team
              may request additional personal data to protect against fraudulent
              requests. The requested documents should be consistent with the
              information on your Augio user account. If you make a Request to
              Delete, our team will ask you to confirm your request before your
              personal information is deleted from the platform.
              <p className="terms-subheader">
                Data Transfer Outside of United States
              </p>
              Augio is based in the United States and follows all applicable
              U.S. laws concerning data storage and privacy. Parties with which
              We affiliate may be based in the United States or outside of the
              U.S., thus it is possible that Your information may be transmitted
              to servers outside of the U.S. The countries and territories with
              which these third parties are affiliated may not have equivalent
              levels of protection for your personal information and may have
              different laws than the U.S. If information is transmitted outside
              of the United States, please be aware it is in the normal
              operation of Augio's business. By engaging with Augio, You consent
              to the storage, transfer, collection, and transmission of your
              data to other countries and territories as necessary.
              <p className="terms-subheader">International Users</p>
              Augio is hosted in the United States. If you are accessing the
              Site from the European Union, Asia, or any other region with laws
              or regulations governing personal data collection, use, and
              disclosure, that differ from United States laws, please note that
              you are transferring your personal data to the United States which
              does not have the same data protection laws as the EU and other
              regions.
              <p className="terms-subheader">
                European Union and European Economic Area:
              </p>
              Users in the European Union (EU) are protected by the General Data
              Protection Regulation (GDPR). The GDPR provides Users located in
              the EU with several rights including the following:
              <br></br>
              <ul>
                <li>The right to be informed • The right of access</li>
                <li>The right to rectification (correction)</li>
                <li>The right to erasure</li>
                <li>The right to restrict processing</li>
                <li>The right to data portability</li>
                <li>The right to object</li>
                <li>The right to not be subject to automated decision making</li>
              </ul>
              <br></br>
                This Website is not intended for children accessing the Website
                from the European Union or the European Economic Area by
                children under the age of 16
              <p className="terms-subheader">Modification of Privacy Policy</p>
              Augio may update or make changes to this Privacy at our sole
              discretion. Notifications of any changes will be sent to Users who
              have notifications enabled for Augio and when Users enter the
              platform after the update. Changes to this Privacy Policy, will be
              posted as the revised Privacy Policy on the Platform with the
              updated date of modification. Users should occasionally check this
              page to ensure that you are aware of any changes to this Privacy
              Policy. Your continued access and use of the Platform after the
              date of such any modifications constitutes acceptance of the
              revised Privacy Policy.
            </p>
            © AUGIO 2025 All Rights Reserved
          </p>
         
        </div>
        <footer>
        <div className="footer-entire-group">
          <div className="footer-links-group">
            <h6>Company</h6>
            <p className="footer-link">
              <HiOutlineMail className="footer-icon" />
              Press: info@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineContactSupport className="footer-icon" />
              Support: support@augio.io
            </p>
          </div>
          <div className="footer-links-group">
            <h6>Quick Links</h6>
            {/*} <div className="footer-social-text footer-text-spacing">Contact</div>*/}
            <Link className="footer-link clickable-link"target="_blank" to="/terms">
              Terms
            </Link>
            <Link className="footer-link clickable-link" target="_blank" to="/membership-terms">
              Membership Agreement
            </Link>

            <Link className="footer-link clickable-link" target="_blank" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="footer-links-group">
            <h6>Social</h6>

            <div className="social-link-row">
              <a
                className="footer-link clickable-link"
                href="https://www.twitter.com/augiomusic_io"
                target="_blank"
              >
                <BsTwitter className="footer-icon"></BsTwitter>
              </a>
              <p className="social-link-name">Twitter</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <p className="social-link-name">Instagram</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <p className="social-link-name">TikTok</p>
            </div>
          </div>
        </div>
        <div className="augio-copyright">© 2025 AUGIO LLC</div>
      </footer>
      </div>
    </div>
  );
};
