import { Link, useNavigate } from "react-router-dom";
import "../Styles/Landing.css";
import { useEffect, useState, useRef } from "react";
import { BsTwitter } from "react-icons/bs";
import { HiOutlineMail, HiDownload } from "react-icons/hi";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { GoLink } from "react-icons/go";
import { MdArrowForwardIos, MdOutlineContactSupport } from "react-icons/md";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { useAuth } from "../Context/AuthContext";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { MdOutlineArticle } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { logCustomEvent } from "../Utilities/EventConfig";

export const LandingPage = () => {
  const logoUrl: string = "/Augio_FullType_Logo_latest.png";
  const gLogoUrl: string = "/Augio_G_Avatar_latest.png";
  const mapImage: string = "/color_map_edit_2.png";
  const iOSAppImage: string = "/mock_01.png";
  const iOSAppStoreDownloadImage: string = "/iosappstoreimage.png";
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [resValue, setResValue] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [pageVisible, setPageVisible] = useState(false);
  const [fadeSuccess, setFadeSuccess] = useState(false);
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateText(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //why join animation
  const artistsRef = useRef<HTMLDivElement | null>(null);
  const fansRef = useRef<HTMLDivElement | null>(null);
  const [isArtistsVisible, setArtistsVisible] = useState(false);
  const [isFansVisible, setFansVisible] = useState(false);

  const checkVisibility = () => {
    const windowHeight = window.innerHeight;
    const artistsPos = artistsRef.current
      ? artistsRef.current.getBoundingClientRect().top
      : 0;
    const fansPos = fansRef.current
      ? fansRef.current.getBoundingClientRect().top
      : 0;

    if (artistsPos < windowHeight * 0.75 && !isArtistsVisible) {
      setArtistsVisible(true);
    }
    if (fansPos < windowHeight * 0.75 && !isFansVisible) {
      setFansVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  //augio benefits animation
  const [isArtistBenefitsVisible, setArtistBenefitsVisible] = useState(false);
  const [isFanBenefitsVisible, setFanBenefitsVisible] = useState(false);

  const artistBenefitsRef = useRef<HTMLDivElement | null>(null);
  const fanBenefitsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (artistBenefitsRef.current) {
        const artistOffset =
          artistBenefitsRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.75;

        if (artistOffset <= triggerPosition && !isArtistBenefitsVisible) {
          setArtistBenefitsVisible(true);
        }
      }

      if (fanBenefitsRef.current) {
        const fanOffset = fanBenefitsRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.75;

        if (fanOffset <= triggerPosition && !isFanBenefitsVisible) {
          setFanBenefitsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isArtistBenefitsVisible, isFanBenefitsVisible]);

  //features carousel
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const slideTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isFeatureVisible, setFeatureVisible] = useState(false);
  const featureRef = useRef<HTMLDivElement | null>(null);
  const [isFading, setIsFading] = useState(false);

  const features = [
    {
      image: "iphone_mock_template_chart.png",
      title: "State Charts",
      description: `
      Every state has incredible independent artists, and Augio's state charts — updated daily — spotlight the top 50. No paid spots, no bias, no secret 
      algorithms, just a fair platform giving artists a chance to shine and fans exciting ways to discover new music.`,
    },

    {
      image: "iphone_mock_template_region.png",
      title: "Region Feeds",
      description: `When music is uploaded, it appears in the feed for the artist's 
      associated region, ensuring it never gets lost. This approach gives every artists' music a dedicated space and provides fans with an organic, location based way to discover new music.`,
    },
    {
      image: "iphone_mock_template_feed.png",
      title: "Personal Feed",
      description: `Follow your favorite artists, keeping all their latest releases and music in one convenient place. You can 
      sort by singles, albums, or randomize your feed for an exciting, fresh music journey.`,
    },
    {
      image: "iphone_mock_template_rectofriendlist.png",
      title: "Recommend Songs In-App",
      description: `Augio offers an in-app music sharing experience, allowing fans to easily add friends, send song recommendations, and access all shared tracks from friends in one convenient place.`,
    },
    {
      image: "iphone_mock_template_songrecs.png",
      title: "Recommend Songs In-App",
      description: `Augio offers an in-app music sharing experience, allowing fans to easily add friends, send song recommendations, and access all shared tracks from friends in one convenient place.`,
    },

    {
      image: "iphone_mock_template_promote_feed.png",
      title: "Music Promotion Tools",
      description: `Augio's music promotion tools simplify the process for artists to promote their music while amplifying their reach by enabling targeted promotion to fans in any state or region.`,
    },

    {
      image: "iphone_mock_template_top100.png",
      title: "Augio Top 100",
      description: `In the world of independent music, this is the chart to be on: the top 100 songs by independent artists in the U.S., updated weekly.`,
    },
  ];

  const nextFeature = () => {
    triggerFade(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    });
  };

  const prevFeature = () => {
    triggerFade(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? features.length - 1 : prevIndex - 1
      );
    });
  };

  const triggerFade = (callback: () => void) => {
    setIsFading(true);
    setTimeout(() => {
      callback();
      setIsFading(false);
    }, 400); // Match fade duration (0.5s)
  };

  const currentFeature = features[currentIndex];

  //region and charts content animation
  const [isContentVisible, setContentVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentOffset = contentRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.95;

        if (contentOffset <= triggerPosition) {
          setContentVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //augio statements fade in
  useEffect(() => {
    let lastScrollY = window.scrollY; // Track scroll direction

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const isScrollingDown = window.scrollY > lastScrollY; // Check scroll direction
        if (entry.isIntersecting && isScrollingDown) {
          entry.target.classList.add("visible");
        }
      });
      lastScrollY = window.scrollY; // Update scroll position after processing entries
    };

    const initializeObserver = () => {
      const containers = document.querySelectorAll(".statement-container");
      const observer = new IntersectionObserver(observerCallback, {
        threshold: 0.1,
      });

      containers.forEach((container) => observer.observe(container));
      return observer;
    };

    const observer = initializeObserver();

    return () => {
      if (observer) {
        observer.disconnect(); // Disconnect the observer when unmounting
      }
    };
  }, []);

  //join waiting list submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    setSubmitSuccess("Sent! Check Your Inbox!");
    setSubmitError("");
    setFadeSuccess(false);

    try {
      await saveEmailToFirestore(email);
      const sendWaitlistEmail = httpsCallable(functions, "sendWaitlistEmail");
      await sendWaitlistEmail({ to: email });

      logCustomEvent("joined_waitinglist_event");

      setEmail("");
      setIsSubmitting(false);

      // fade out animation
      setTimeout(() => {
        setFadeSuccess(true);

        setTimeout(() => {
          setSubmitSuccess("");
          setFadeSuccess(false);
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error("Error sending welcome email:", error);

      setSubmitError("Failed to send. Try again.");
      setSubmitSuccess("");

      setIsSubmitting(false);
    }
  };

  const saveEmailToFirestore = async (email: string) => {
    try {
      await addDoc(collection(firestore, "waitingListEmails"), {
        email: email,
        timestamp: new Date(),
      });
      console.log(
        "Email saved to Firestore, trigger should send an email shortly."
      );
    } catch (error) {
      console.error("Error saving email to Firestore:", error);
      throw new Error("Failed to save email to Firestore.");
    }
  };

  return (
    <div>
      <div className="landing-page-container">
        <div>
          <div className="menu-links">
            <img className="augio-tranparent-g" src={gLogoUrl}></img>

            <div>
              <a
                className="mobile-app-link-btn menu-link-spacing"
                href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  logCustomEvent("mobile_app_dl_button_click", {
                    button_name: "Mobile App Download",
                    page_location: window.location.pathname,
                  })
                }
              >
                Mobile App
              </a>

              <Link className="register-btn menu-link-spacing" to="/register">
                Register
              </Link>
              <Link className="login-btn menu-link-spacing" to="/login">
                Login
              </Link>
            </div>
          </div>

          <div className="top-page-text">
            <div className="landing-image-text-container">
              <img className="augio-landing-image" src={mapImage}></img>
              <div className="custom-font-group">
                <div
                  className={`text-over-image-01 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Open The Door
                </div>
                <div
                  className={`text-over-image-02 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  To A Whole
                </div>
                <div
                  className={`text-over-image-03 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  New World
                </div>
                <div
                  className={`text-over-image-04 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Of Music
                </div>
                <div
                  className={`text-over-image-05 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Discovery
                </div>
              </div>
            </div>
          </div>

          <div className="intro-text">
            <h1
              className={`landing-large-text fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
            >
              INTRODUCING
            </h1>
            <img
              className={`landing-logo fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
              src={logoUrl}
              alt="augio-colorized-mock"
            />
            <p
              className={`introducing-quote-text fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
            >
              Current music streaming platforms face mounting challenges:
              oversaturation, biased partnerships, and obscure algorithms that
              favor major labels, leaving independent artists and fans behind.
              <br></br>
              Augio is here to change that.
            </p>
          </div>

          <h1 className="gradient-header-text">
            Augio is a location-based streaming platform exclusive to
            independent artists
          </h1>

          <section className="why-join-section">
            <div
              ref={artistsRef}
              className={`artists-why-join ${
                isArtistsVisible ? "in-view" : ""
              }`}
            >
              <p className="why-join-artists">ARTISTS</p>
              <p className="why-join-description">
                Building a fanbase and earning from your music starts with being
                discovered. Making it easy for fans to find your music is
                essential.
              </p>
            </div>

            <div
              ref={fansRef}
              className={`fans-why-join ${isFansVisible ? "in-view" : ""}`}
            >
              <p className="why-join-fans">MUSIC FANS</p>
              <p className="why-join-description">
                Independent artists make up 96% of daily uploads, but streaming
                platforms prioritize major label artists, leaving a world of new
                music and artists undiscovered.
              </p>
            </div>
          </section>

          <section className="augio-statements-container">
            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">
                  AUGIO: EMPOWERING INDEPENDENT ARTISTS AND MUSIC FANS
                </p>
                <p className="statement-bigtext">
                  AN EXPLOSION OF MUSIC, A CRISIS OF VISIBILITY
                </p>
                <p className="statement-subtext">
                  More than 100,000 tracks are uploaded daily to streaming
                  platforms, and 96% are from independent artists. Yet, existing
                  platforms prioritize major label artists, leaving independent
                  artists' music behind.
                </p>
              </div>
              <FaLink className="link-icon" />
            </div>

            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">
                  THE STATUS QUO: FAVORING THE FEW
                </p>
                <p className="statement-bigtext">
                  STREAMING IS BUILT FOR MAJOR LABELS
                </p>
                <p className="statement-subtext">
                  Existing platforms cater to major label artists and their
                  distributors. Independent artists lack an unbiased streaming
                  platform that solely highlights their music, and fans miss out
                  on discovering a world of new music.
                </p>
              </div>
              <FaLink className="link-icon" />
            </div>

            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">
                  THE CHALLENGE: OVERSATURATION
                </p>
                <p className="statement-bigtext">
                  STANDING OUT IS NEARLY IMPOSSIBLE
                </p>
                <p className="statement-subtext">
                  With 100,000+ tracks flooding platforms daily, independent
                  artists struggle to break through algorithms that are designed
                  to promote the music of major labels.
                </p>
              </div>
              <FaLink className="link-icon" />
            </div>

            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">FOR MUSIC FANS</p>
                <p className="statement-bigtext">A LACK OF TRUE DISCOVERY</p>
                <p className="statement-subtext">
                  Fans are forced to rely on pre-curated playlists and
                  algorithms, often defaulting to familiar artists. Genuine
                  discovery feels missing, limiting the connection to fresh,
                  independent music.
                </p>
              </div>
              <FaLink className="link-icon" />
            </div>

            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">A BROKEN SYSTEM</p>
                <p className="statement-bigtext">ORGANIC DISCOVERY REDEFINED</p>
                <p className="statement-subtext">
                  Paid playlist spots, label-owned curation, and obscure
                  algorithms dominate streaming. Augio levels the playing field
                  by championing fair and transparent music discovery.
                </p>
              </div>
              <FaLink className="link-icon mobile-chain-link" />
            </div>

            <div className="statement-wrapper">
              <div className="statement-container">
                <p className="statement-header">
                  BRINGING BACK THE FUN OF MUSIC DISCOVERY
                </p>
                <p className="statement-bigtext">
                  MAKING SHARING SIMPLE AND SOCIAL
                </p>
                <p className="statement-subtext">
                  Sharing music should be effortless and social, not a tedious
                  and multi-step process of copying links and sending messages.
                  Augio transforms this into a seamless, social experience,
                  reigniting the fun of discovering and sharing music.
                </p>
              </div>
            </div>
          </section>

          <section className="mobile-app-preview-section">
            <h2 className="subheader-text">SEE THE FEATURES</h2>
            <div className={`carousel-content ${isFading ? "fade" : ""}`}>
              <img
                className="ios-app-image"
                src={currentFeature.image}
                alt={currentFeature.title}
              />
              <h2 className="custom-font-heading">{currentFeature.title}</h2>
              <p className="ios-feature-preview-text">
                {currentFeature.description}
              </p>
            </div>
            <div className="carousel-controls left-arrow">
              <RiArrowLeftSLine
                className="carousel-arrow"
                onClick={prevFeature}
              />
              <RiArrowRightSLine
                className="carousel-arrow right-arrow"
                onClick={nextFeature}
              />
            </div>
          </section>

          <section className="augio-benefits">
            <div className="artist-benefits" ref={artistBenefitsRef}>
              <div
                className={`benefits-title artist-benefits-header ${
                  isArtistBenefitsVisible
                    ? "benefits-text-final"
                    : "benefits-text-init"
                }`}
              >
                ARTISTS
              </div>
              <ul className="benefits-list">
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Major Artists or Labels
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Paid Playlists
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Music Promotion Tools
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Biased Partnerships
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Keep 100% of Profits from Music Sales
                </li>
              </ul>
            </div>

            <div className="fan-benefits" ref={fanBenefitsRef}>
              <div
                className={`benefits-title fans-benefits-header ${
                  isFanBenefitsVisible
                    ? "benefits-text-final"
                    : "benefits-text-init"
                }`}
              >
                MUSIC FANS
              </div>
              <ul className="benefits-list">
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Search Music Geographically
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Charts for Every State
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Feeds for Every Region
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Recommended Artists Near You
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Send Songs to Friends In-App
                </li>
              </ul>
            </div>
          </section>

          <div
            className={`main-content-section ${
              isContentVisible ? "visible" : ""
            }`}
            ref={contentRef}
          >
            <div className="main-content-left">
              <div className="main-content-title">
                Augio uniquely
                <br></br>
                organizes music
                <br></br>
                by location
                <br></br>
                to make it
                <br></br>
                stand out
              </div>
              <p className="main-content-subtext">
                Sorting music by location amplifies your music's visibility,
                helping music fans and potential collaborators discover your
                music easier.
              </p>

              <div className="main-content-sub-container">
                <div className="how-it-works-header">Augio Charts</div>
                <p className="how-it-works-subtext">
                  There's a chart for every state, giving artists a new way to
                  standout and music fans exciting new ways to discover music.
                </p>
                <p className="how-it-works-subtext">
                  Everyone's music should have an equal shot at recognition, so
                  we don't use biased algorithms or let anyone pay for chart
                  positions.
                </p>
              </div>
            </div>

            <div className="main-content-right">
              <img
                className="zones-group-desktop"
                src="https://augio-other-images.s3.amazonaws.com/zones-group-landing-page.png"
              ></img>

              <div className="how-it-works-header">Augio Regions</div>

              <p className="how-it-works-subtext">
                Each state is broken into two to three regions based on
                population density. All the music you upload is added to your
                regions's uploads page, giving fans an easy way to find your
                music.
              </p>
              <p className="how-it-works-subtext">
                100,000+ songs are uploaded to streaming platforms daily.
                Augio's innovative sorting methods increase exposure to music.
              </p>
            </div>
          </div>

          <div className="signup-container">
            <div className="musicfans-waiting-list-container">
              <p className="musicfans-join-title">DOWNLOAD THE AUGIO IOS APP</p>
              <a
                href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  logCustomEvent("mobile_app_dl_button_click", {
                    button_name: "Mobile App Download",
                    page_location: window.location.pathname,
                  })
                }
                title="Download the Augio Mobile App"
                aria-label="Download the Augio Mobile App"
              >
                <img
                  className="appstoredownload-image"
                  src={iOSAppStoreDownloadImage}
                ></img>
              </a>
              <div className="signup-info">
                <form className="waitlist-form" onSubmit={handleSubmit}>
                  <input
                    className="signup-input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  <button
                    className="signup-submit-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Join
                  </button>
                </form>
                {submitSuccess && (
                  <div
                    className={`waitlist-email-success ${
                      fadeSuccess ? "fade-out" : ""
                    }`}
                  >
                    {submitSuccess}
                  </div>
                )}

                {submitError && (
                  <div className="waitlist-email-error">{submitError}</div>
                )}
              </div>
            </div>

            <div className="artists-signup-container">
              <p className="artists-join-title">SIGN UP</p>
              <Link className="artists-register-link" to="/register">
                Register
              </Link>
            </div>
          </div>

          <p className="founder-quote">
            "We created Augio to provide independent artists with a streaming
            platform that prioritizes and showcases their music, addressing
            major issues overlooked by mainstream platforms. We also had a
            desire to transform music discovery for fans, making it easier than
            ever to find new artists and music."
          </p>

          <p className="quote-credit">Joe Murphy</p>
          <p className="quote-credit">Founder of Augio</p>

          <div className="bottom-page-content"></div>
        </div>
      </div>

      <footer>
        <div className="footer-entire-group">
          <div className="footer-links-group">
            <h6>Company</h6>
            <p className="footer-link">
              <HiOutlineMail className="footer-icon" />
              Press: info@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineContactSupport className="footer-icon" />
              Support: support@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineArticle className="footer-icon" />
              <Link
                className="footer-link clickable-link"
                target="_blank"
                to="https://augio.medium.com/"
              >
                Blog
              </Link>
            </p>
          </div>
          <div className="footer-links-group">
            <h6>Quick Links</h6>
            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/terms"
            >
              Terms
            </Link>
            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/membership-terms"
            >
              Membership Agreement
            </Link>

            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="footer-links-group">
            <h6>Social</h6>

            <div className="social-link-row">
              <a
                className="footer-link quick-link"
                href="https://www.twitter.com/augiomusic_io"
                target="_blank"
              >
                <BsTwitter className="footer-icon"></BsTwitter>
              </a>
              <p className="social-link-name">Twitter</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <p className="social-link-name">Instagram</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <p className="social-link-name">TikTok</p>
            </div>
          </div>
        </div>
        <div className="augio-copyright">© 2025 AUGIO LLC</div>
      </footer>
    </div>
  );
};
